
$(function(){
    antiJunkMail();

    updateScrollTopOffset();
    doEventPeriodically($(window), "resize", 100, updateScrollTopOffset);

    $(".js-shrinkableHeader").imarcomShrinkableHeader();

    $(".js-glasses-element").on("click", function() {
        if (typeof($(this).data("video")) != 'undefined') {
            var video = $(this).data("video");

            $.fancybox({
                "content": '<iframe width="560" height="315" src="https://www.youtube.com/embed/'+video+'?rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>',
                "padding": 0
            });
        } else {
            var image = $(this).data("src");

            $.fancybox({
                "content": '<div class="glasses-fancybox"><img src="'+image+'" alt="" /></div>',
                "padding": 0
            });
        }
    });

    if ($('.socialWidget').length) {
        var url = $('meta[property="og:url"]').attr('content');
        var title = $('meta[property="og:title"]').attr('content');
        var description = $('meta[property="og:description"]').attr('content');
        var image = $('meta[property="og:image"]').eq(0).attr('content');

        var facebookHref = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
        var linkedinHref = 'https://www.linkedin.com/shareArticle?mini=true&url=' + url;
        var pinterestHref = 'https://pinterest.com/pin/create/button/?url=' + image + '&media=Media&description=Description';
        var twitterHref = 'https://twitter.com/intent/tweet?text=' + url;
        var emailHref = 'mailto:?subject=' + title + '&body=' + description + ' : ' + url;

        $('.js-socialWidget-facebook').attr('href', facebookHref);
        $('.js-socialWidget-linkedin').attr('href', linkedinHref);
        $('.js-socialWidget-pinterest').attr('href', pinterestHref);
        $('.js-socialWidget-twitter').attr('href', twitterHref);
        $('.js-socialWidget-email').attr('href', emailHref);
    }
});
